/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';
import { IoEyeOutline } from 'react-icons/io5';
import { css } from '@emotion/react';
import CustomTable from '../../../components/Single/CustomTable';
import SelectCircle from '../../../components/Single/SelectCircle';
import scrollbarStyles from '../../../components/Single/Scrollbar';
import IndividualLeadsInput from './IndividualLeadsInput';

const emptyStateStyle = css({
  textAlign: 'center',
  color: '#6B7280',
  padding: '32px 16px',
  fontSize: '0.875rem'
});

const tableHeaderStyle = css`
  margin-bottom: 8px;
`;

const totalContactsStyle = css`
  font-size: 0.95em;
  color: #111827;
  margin-bottom: 4px;
`;

const duplicatesTextStyle = css`
  color: #666;
  font-size: 0.8em;
  font-style: italic;
  margin-bottom: 8px;
`;

const flexContainerStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',
  minWidth: 0,
  padding: '12px 16px'
});

const groupNameStyle = css({
  flex: '1',
  marginRight: 'auto',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  color: '#111827',
  fontSize: '0.875rem',
  minWidth: 0
});

const contactCountStyle = css({
  color: '#6B7280',
  fontSize: '0.875rem',
  fontWeight: 500
});

const viewIconStyle = css({
  width: 20,
  height: 20,
  padding: 0,
  border: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  backgroundColor: 'transparent',
  color: '#6B7280',
  transition: 'transform 0.2s ease, color 0.2s ease',
  '&:hover': {
    color: '#4CAF50',
    transform: 'scale(1.2)',
  },
  '&:active': {
    transform: 'scale(0.95)',
  },
  '&:focus': {
    outline: 'none',
    boxShadow: 'none',
  }
});

const rightAlignedContainerStyle = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  padding: '12px 16px'
});

const tableContainerStyle = css`
  position: relative;
  background: white;
  border: 1px solid #E5E7EB;
  border-radius: 8px;
  overflow: hidden;
  
  table {
    border: none;
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    background: white;
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: #F9FAFB;
    
    tr {
      background-color: #F9FAFB;
    }

    th {
      border: none;
      background-color: #F9FAFB;
      height: 44px;
      padding: 0 16px;
      font-weight: 600;
      color: #111827;
      font-size: 0.875rem;
      text-transform: uppercase;
      box-shadow: inset 0 -1px 0 #E5E7EB;
    }
  }

  tbody {
    tr {
      td {
        border: none;
        background-color: white;
        box-shadow: inset 0 -1px 0 #E5E7EB;
      }

      &:last-child td {
        box-shadow: none;
      }
    }
  }

  ${scrollbarStyles}
`;

const individualLeadsSectionStyle = css`
  margin-top: 24px;
  padding-top: 16px;
  border-top: 1px solid #E5E7EB;
`;

const LeadsTable = ({
  groups = [],
  selectedGroups = [],
  onLeadSelection,
  onViewGroup,
  onAddIndividualLead
}) => {
  const sortedGroups = useMemo(
    () =>
      [...groups].sort((a, b) => new Date(b.create_time) - new Date(a.create_time)),
    [groups]
  );

  const headers = [
    <th key="leads" style={{ textAlign: 'left', width: '50%' }}>YOUR LEADS</th>,
    <th key="view" style={{ textAlign: 'center', width: '25%' }}>VIEW</th>,
    <th key="contacts" style={{ textAlign: 'right', width: '25%' }}>CONTACTS</th>
  ];

  const data = sortedGroups.map((group) => [
    <div key={group.id} css={flexContainerStyle}>
      <SelectCircle
        isSelected={selectedGroups.some((selectedGroup) => selectedGroup.id === group.id)}
        onClick={() => onLeadSelection(group.id)}
      />
      <span css={groupNameStyle} title={group.name}>
        {group.name}
      </span>
    </div>,
    <div key={`view-${group.id}`} css={rightAlignedContainerStyle}>
      <button
        css={viewIconStyle}
        onClick={() => onViewGroup(group.id)}
        aria-label={`View ${group.name} details`}
        title="View details"
      >
        <IoEyeOutline size={20} css={{ color: 'inherit' }} />
      </button>
    </div>,
    <div key={`contact-${group.id}`} css={[rightAlignedContainerStyle, { justifyContent: 'flex-end' }]}>
      <span css={contactCountStyle}>
        {group.contacts?.toLocaleString() ?? 'N/A'}
      </span>
    </div>
  ]);

  const renderMainTable = () => (
    <>
      <div css={tableHeaderStyle}>
        <div css={totalContactsStyle}>
          <div css={duplicatesTextStyle}>
            Duplicates will be removed
          </div>
        </div>
      </div>
      <div css={tableContainerStyle}>
        <CustomTable
          headers={headers}
          data={data}
          emptyMessage="No leads data available."
        />
      </div>
    </>
  );

  return (
    <>
      {groups.length === 0 ? (
        <div css={emptyStateStyle}>
          No leads data available. Import your first leads group to get started.
        </div>
      ) : (
        renderMainTable()
      )}
      {/* remove individualLeads
      <div css={individualLeadsSectionStyle}>
        <IndividualLeadsInput onAddLead={onAddIndividualLead} />
      </div> */}
    </>
  );
};

export default LeadsTable;