/**
* StepsDeploy.js
* This is the main deployment page component
* Uses logic from: deployHooks.js
* Displays: SelectedGroups, BusinessPreview, PostPreview, DeploymentScheduler
*/

/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { useDeployLogic } from './deployHooks';  // Import the hook that contains all our logic
import SelectedGroups from './SelectedGroups';   // Shows selected leads and groups
import BusinessPreview from './BusinessPreview'; // Shows selected business profile
import PostPreview from './PostPreview';        // Shows selected post preview
import DeploymentScheduler from './DeploymentScheduler'; // Date/time scheduler
import DeploymentConfirmation from './DeploymentConfirmation'; // Success modal
import CurrentCampaignWindow from '../../../components/CurrentCampaignWindow/CurrentCampaignWindow';
import BuyCredits from './BuyCredits';          // Buy credits modal
import ChatButton from '../../../components/Single/ContactButton.js';
import LoadingCursor from '../../../components/Single/LoadingCursor';

// Styles for the main container
const containerStyles = css`
 display: flex;
 flex-direction: column;
 align-items: center;
 padding: 20px;
 min-height: 100vh;
`;

// Layout for the three main preview sections
const previewContainerStyles = css`
 display: grid;
 grid-template-columns: repeat(3, minmax(380px, 1fr));
 gap: 2rem;
 width: 100%;
 max-width: 1400px;
 margin: 2rem auto;
 padding: 0 1.5rem;
 justify-content: space-between;
 align-items: start;
`;

// Styles for each column in the grid
const columnStyles = css`
 width: 100%;
 min-width: 380px;
 min-height: 600px;
 display: flex;
 flex-direction: column;
 gap: 2rem;
`;

// Styles for each section box
const sectionStyles = css`
 width: 100%;
 background: white;
 border-radius: 8px;
 box-shadow: 0 2px 4px rgba(0,0,0,0.1);
 padding: 1.5rem;
`;

// Error message styles
const errorStyles = css`
 color: red;
 margin-bottom: 10px;
`;

// -------- Simulation Section Start --------
// // Bottom fixed button container
// const buttonContainerStyles = css`
//   position: fixed;
//   bottom: 20px;
//   left: 50%;
//   transform: translateX(-50%);
//   display: flex;
//   gap: 10px;
// `;

// // Base styles for buttons
// const buttonBaseStyles = css`
//   padding: 10px 20px;
//   font-size: 16px;
//   border: none;
//   border-radius: 5px;
//   cursor: pointer;
//   transition: all 0.3s ease;
// `;

// // Simulate deploy button styles
// const simulatedDeployButtonStyles = css`
//   ${buttonBaseStyles}
//   background-color: #808080;
//   color: white;
//   &:hover { background-color: #606060; }
// `;

// // Buy credits button styles
// const buyCreditsButtonStyles = css`
//   ${buttonBaseStyles}
//   background-color: #4CAF50;
//   color: white;
//   &:hover { background-color: #45a049; }
// `;
// -------- Simulation Section End --------

const StepsDeploy = () => {
   // Get all our data and handlers from the hook
   const {
       selectedGroups,
       totalSelectedContacts,
       totalWithoutDuplicates,
       selectedBusiness,
       selectedPost,
       postData,
       error,
       scheduleDate,
       showConfirmation,
       isSimulatedDeployment,
       isBuyCreditsOpen,
       individualLeads,
       selectedIndividualLeads,
       isLoading,
       handleDateChange,
       handleDeploy,
       handleSimulatedDeploy,
       handleCloseConfirmation,
       setIsBuyCreditsOpen
   } = useDeployLogic();

   return (
       <>
         <LoadingCursor />
         <div css={containerStyles}>
           {/* Campaign progress indicator */}
           <CurrentCampaignWindow currentStep={4} isFinalStep={true}>
               <p>Total Contacts: {totalSelectedContacts}</p>
           </CurrentCampaignWindow>
           
           <h2>Deploy Campaign</h2>

           {/* Error display */}
           {error && <div css={errorStyles}>{error}</div>}

           {/* Main content grid */}
           <div css={previewContainerStyles}>
               {/* Left column - Selected leads */}
               <div css={sectionStyles}>
                   <SelectedGroups 
                       selectedGroups={selectedGroups}
                       totalSelectedContacts={totalSelectedContacts}
                       totalWithoutDuplicates={totalWithoutDuplicates}
                       individualLeads={individualLeads}
                       selectedIndividualLeads={selectedIndividualLeads}
                   />
               </div>

               {/* Middle column - Business & Schedule */}
               <div css={columnStyles}>
                   <div css={sectionStyles}>
                       <BusinessPreview selectedBusiness={selectedBusiness} />
                   </div>
                   <div css={sectionStyles}>
                       <DeploymentScheduler 
                           scheduleDate={scheduleDate}
                           onDateChange={handleDateChange}
                           onDeploy={handleDeploy}
                           isLoading={isLoading}
                       />
                   </div>
               </div>

               {/* Right column - Post preview */}
               <div css={sectionStyles}>
                   <PostPreview 
                       postImage={postData?.postImage}
                       postText={postData?.postText}
                       buttons={postData?.buttons}
                   />
               </div>
           </div>

           {/* Modals and overlays */}
           {showConfirmation && (
               <DeploymentConfirmation onClose={handleCloseConfirmation} />
           )}

           {/* -------- Simulation Section Start -------- */}
           {/* <div css={buttonContainerStyles}>
               <button 
                   css={simulatedDeployButtonStyles} 
                   onClick={handleSimulatedDeploy}
                   disabled={isSimulatedDeployment}
               >
                   {isSimulatedDeployment ? 'Simulating...' : 'Simulate Deploy'}
               </button>
               <button 
                   css={buyCreditsButtonStyles} 
                   onClick={() => setIsBuyCreditsOpen(true)}
               >
                   Buy Credits
               </button>
           </div> */}
           {/* -------- Simulation Section End -------- */}

           <ChatButton type="chatNow" location="rightCorner" />
           {isBuyCreditsOpen && (
               <BuyCredits onClose={() => setIsBuyCreditsOpen(false)} />
           )}
         </div>
       </>
   );
};

export default StepsDeploy;